import { createRouter, createWebHistory, type NavigationGuardNext, type RouteLocationNormalized } from 'vue-router';
import { useEventBus } from '@vueuse/core';
import { i18nt } from '@/locales';
import { useUser } from '@/stores/user';
import { useClient } from '@/stores/client';
import { useCookies } from '@vueuse/integrations/useCookies';
import { event as gEvent } from 'vue-gtag';
import dayjs from 'dayjs';
import { useResetStore } from '@/utils/useResetStore';

const saveEvent = useEventBus<string>('save');
const onBoardingEvent = useEventBus<string>('onboarding');

const cookies = useCookies();

const isAuthenticated = (): boolean => {
  const userStore = useUser();
  return userStore.authToken !== '';
};

const isSuperAdmin = (): boolean | undefined => {
  const userStore = useUser();
  return userStore?.user?.role.includes('superadmin');
};

const isOrganisationOwner = (): boolean | undefined => {
  const userStore = useUser();
  return userStore?.user?.role.includes('organisation.owner') && typeof userStore?.user?.organisationId === 'string';
};

const updateClient = async (forceClientUpdate: boolean): Promise<void> => {
  const userStore = useUser();
  const clientStore = useClient();
  if (!clientStore.client || (isSuperAdmin() && !clientStore.clients) || dayjs().diff(clientStore.lastUpdate, 'minute') > 5 || forceClientUpdate) {
    if (isSuperAdmin() || isOrganisationOwner()) {
      const allowedClients = await clientStore.getClients();
      if (isSuperAdmin() && userStore.clientId) {
        await clientStore.getClient(userStore.clientId);
      } else await clientStore.getClient(allowedClients[0].id);
    } else await clientStore.getClient(userStore.user?.client?.id);
  }
};

const initSession = async (to: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
  try {
    const forceClientUpdate = to.query?.forceClientUpdate === 'true';
    await updateClient(forceClientUpdate);
    const clientStore = useClient();
    if (!isSuperAdmin() && !isOrganisationOwner() && clientStore.client?.status === 'churned')
      next({ name: 'error', query: { status: 'client_churned' } });
  } catch (error) {
    next({ name: 'error', query: { status: error?.response?.status || 401 } });
  }
};

const canAccess = (featureId: string, roles: string[], next: NavigationGuardNext): void => {
  const clientStore = useClient();
  const userStore = useUser();
  if (!isSuperAdmin() && (!clientStore.hasFeature(featureId) || !userStore.hasRight(roles))) next({ name: 'error', query: { status: '403' } });
  next();
};

const clearLocalStorage = (): void => {
  localStorage.clear();
  const resetStore = useResetStore();
  resetStore.all();
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: { path: '/dashboard' },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard.vue'),
      meta: { layout: 'Default' },
      beforeEnter: async (to, from, next): Promise<void> => {
        await initSession(to, next);
        next();
      },
    },
    {
      path: '/notifications',
      name: 'notifications',
      redirect: { name: 'incoming-notifications' },
      component: () => import('@/views/notifications/notifications.vue'),
      beforeEnter: async (to, from, next): Promise<void> => {
        await initSession(to, next);
        canAccess('push', ['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'push'], next);
      },
      children: [
        {
          path: 'incoming',
          name: 'incoming-notifications',
          component: () => import('@/views/notifications/notifications-list.vue'),
          meta: { breadcrumbSkip: true },
        },
        {
          path: 'past',
          name: 'past-notifications',
          component: () => import('@/views/notifications/notifications-list.vue'),
          meta: { breadcrumbSkip: true },
        },
        {
          path: 'create',
          name: 'notification-create',
          component: () => import('@/views/notifications/notification.vue'),
          meta: { canGoBack: true },
        },
        {
          path: 'duplicate/:id',
          name: 'notification-duplicate',
          component: () => import('@/views/notifications/notification.vue'),
          meta: { canGoBack: true },
        },
        {
          path: ':id',
          name: 'notification',
          component: () => import('@/views/notifications/notification.vue'),
          meta: { canGoBack: true },
        },
      ],
    },
    {
      path: '/consultation',
      name: 'consultation',
      redirect: { name: 'ideas' },
      beforeEnter: async (to, from, next): Promise<void> => {
        await initSession(to, next);
        canAccess('consultation', ['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'editor'], next);
      },
      children: [
        {
          path: 'ideas',
          name: 'ideas',
          component: () => import('@/views/consultation/ideas.vue'),
          redirect: { name: 'ideas-list' },
          children: [
            {
              path: '',
              name: 'ideas-list',
              component: () => import('@/views/consultation/ideas/ideas-list.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'create',
              name: 'ideas-create',
              component: () => import('@/views/consultation/ideas/create-idea.vue'),
              meta: { canGoBack: true },
            },
            {
              path: ':id',
              name: 'idea',
              component: () => import('@/views/consultation/ideas/idea.vue'),
              meta: { canGoBack: true },
            },
          ],
        },
        {
          path: 'configuration',
          name: 'configuration',
          children: [
            {
              path: '',
              name: 'configuration',
              component: () => import('@/views/consultation/configuration/configuration.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'themes/create',
              name: 'theme-create',
              component: () => import('@/views/consultation/configuration/theme.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'themes/duplicate/:id',
              name: 'theme-duplicate',
              component: () => import('@/views/consultation/configuration/theme.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'themes/:id',
              name: 'theme-edit',
              component: () => import('@/views/consultation/configuration/theme.vue'),
              meta: { canGoBack: true },
            },
          ],
        },
      ],
    },
    {
      path: '/garbage',
      name: 'garbageCollection.featureName',
      redirect: { name: 'garbageCollection.collections' },
      beforeEnter: async (to, from, next): Promise<void> => {
        await initSession(to, next);
        canAccess('garbage', ['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'garbage'], next);
      },
      children: [
        {
          path: 'collections',
          name: 'garbageCollection.collections',
          redirect: { name: 'garbageCollection.collectionsList' },
          children: [
            {
              path: '',
              name: 'garbageCollection.collectionsList',
              component: () => import('@/views/garbage-collection/collections/collections-list.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'week',
              name: 'garbageCollection.weekDates',
              component: () => import('@/views/garbage-collection/collections/collections-list.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'month',
              name: 'garbageCollection.monthDates',
              component: () => import('@/views/garbage-collection/collections/collections-list.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'custom',
              name: 'garbageCollection.customDates',
              component: () => import('@/views/garbage-collection/collections/collections-list.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'collection-create',
              name: 'garbageCollection.collectionCreate',
              component: () => import('@/views/garbage-collection/collections/collection-detail.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'collection-edit/:id',
              name: 'garbageCollection.collectionEdit',
              component: () => import('@/views/garbage-collection/collections/collection-detail.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'collection-date-edit/:id',
              name: 'garbageCollection.collectionDateEdit',
              component: () => import('@/views/garbage-collection/collections/collection-date-detail.vue'),
              meta: { canGoBack: true },
            },
          ],
        },
        {
          path: 'deposits',
          name: 'garbageCollection.deposits',
          children: [
            {
              path: '',
              name: 'garbageCollection.depositsList',
              meta: { breadcrumbSkip: true },
              component: () => import('@/views/garbage-collection/deposits/deposits.vue'),
            },
            {
              path: 'add',
              name: 'garbageCollection.depositsAdd',
              component: () => import('@/views/garbage-collection/deposits/deposits.vue'),
              meta: { canGoBack: true, breadcrumbSkip: true },
            },
            {
              path: 'create',
              name: 'garbageCollection.depositCreate',
              component: () => import('@/views/infrastructures/infrastructure.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'edit/:id',
              name: 'garbageCollection.depositEdit',
              component: () => import('@/views/infrastructures/infrastructure.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'pickDeposits',
              name: 'garbageCollection.depositsPick',
              component: () => import('@/views/infrastructures/pickInfrastructures.vue'),
              meta: { canGoBack: true },
            },
          ],
        },
        {
          path: 'configuration',
          name: 'garbageCollection.configuration',
          redirect: { name: 'garbageCollection.garbageTypes' },
          children: [
            {
              path: 'garbage-types',
              name: 'garbageCollection.garbageTypes',
              component: () => import('@/views/garbage-collection/configuration/configuration.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'garbage-types/add',
              name: 'garbageCollection.garbageTypeAdd',
              component: () => import('@/views/garbage-collection/configuration/configuration.vue'),
              meta: { breadcrumbSkip: true, canGoBack: true },
            },
            {
              path: 'garbage-types/create',
              name: 'garbageCollection.garbageTypeCreate',
              component: () => import('@/views/garbage-collection/configuration/garbage-type.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'garbage-types/edit/:id',
              name: 'garbageCollection.garbageTypeEdit',
              component: () => import('@/views/garbage-collection/configuration/garbage-type.vue'),
              meta: { canGoBack: true },
            },
            {
              path: 'sorting-guide',
              name: 'garbageCollection.sortingGuide',
              component: () => import('@/views/garbage-collection/configuration/configuration.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'slots',
              name: 'garbageCollection.slots',
              component: () => import('@/views/garbage-collection/configuration/configuration.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'zones',
              name: 'garbageCollection.zones',
              component: () => import('@/views/garbage-collection/configuration/configuration.vue'),
              meta: { breadcrumbSkip: true },
            },
            {
              path: 'zones/create',
              name: 'garbageCollection.zoneCreate',
              component: () => import('@/views/zone.vue'),
              meta: { canGoBack: true, feature: 'garbage' },
            },
            {
              path: 'zones/edit/:id',
              name: 'garbageCollection.zoneEdit',
              component: () => import('@/views/zone.vue'),
              meta: { canGoBack: true, feature: 'garbage' },
            },
          ],
        },
      ],
    },
    {
      path: '/infrastructures',
      name: 'infrastructures',
      redirect: { name: 'infrastructures' },
      children: [
        {
          path: '',
          name: 'infrastructures',
          component: () => import('@/views/infrastructures/infrastructures.vue'),
          meta: { breadcrumbSkip: true },
        },
        {
          path: 'add',
          name: 'infrastructuresAdd',
          component: () => import('@/views/infrastructures/infrastructures.vue'),
          meta: { breadcrumbSkip: true, canGoBack: true },
        },
        {
          path: 'create',
          name: 'infrastructureCreate',
          component: () => import('@/views/infrastructures/infrastructure.vue'),
          meta: { canGoBack: true },
        },
        {
          path: 'edit/:id',
          name: 'infrastructureEdit',
          component: () => import('@/views/infrastructures/infrastructure.vue'),
          meta: { canGoBack: true },
        },
      ],
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('@/views/statistics.vue'),
      beforeEnter: async (to, from, next): Promise<void> => {
        await initSession(to, next);
        canAccess('statistic', ['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'statistician'], next);
      },
    },
    {
      path: '/zones',
      name: 'zones',
      redirect: { name: 'zones-list' },
      beforeEnter: async (to, from, next): Promise<void> => {
        await initSession(to, next);
        canAccess('zone', ['superadmin'], next);
      },
      children: [
        {
          path: '',
          name: 'zones-list',
          component: () => import('@/views/zones.vue'),
          meta: { breadcrumbSkip: true },
        },
        {
          path: 'create',
          name: 'zone-create',
          component: () => import('@/views/zone.vue'),
          meta: { canGoBack: true },
        },
        {
          path: 'edit/:id',
          name: 'zone-edit',
          component: () => import('@/views/zone.vue'),
          meta: { canGoBack: true },
        },
      ],
    },
    {
      path: '/account',
      name: 'account.main',
      redirect: { path: '/account/profile' },
      beforeEnter: async (to, from, next): Promise<void> => {
        next({ name: 'error', query: { status: '403' } });
      },
      children: [
        {
          path: 'profile',
          name: 'account.profile',
          component: () => import('@/views/account/profile.vue'),
        },
        {
          path: 'preferences',
          name: 'account.preferences',
          component: () => import('@/views/account/preferences.vue'),
        },
        {
          path: 'notifications',
          name: 'account.notifications',
          component: () => import('@/views/account/notifications.vue'),
        },
        {
          path: 'plan',
          name: 'account.plan',
          component: () => import('@/views/account/plan.vue'),
        },
      ],
    },
    {
      path: '/permissions',
      name: 'permissions.main',
      redirect: { path: '/permissions/cities' },
      children: [
        {
          path: 'users',
          name: 'permissions.users',
          component: () => import('@/views/permissions/users.vue'),
          beforeEnter: async (to, from, next): Promise<void> => {
            next({ name: 'error', query: { status: '403' } });
          },
        },
        {
          path: 'roles',
          name: 'permissions.roles',
          component: () => import('@/views/permissions/roles.vue'),
          beforeEnter: async (to, from, next): Promise<void> => {
            next({ name: 'error', query: { status: '403' } });
          },
        },
        {
          path: 'cities',
          name: 'permissions.cities',
          component: () => import('@/views/permissions/cities.vue'),
          beforeEnter: async (to, from, next): Promise<void> => {
            const clientStore = useClient();
            if (isSuperAdmin() && clientStore.isAnEPCI()) next();
            next({ name: 'error', query: { status: '403' } });
          },
        },
      ],
    },
    {
      path: '/app-management',
      name: 'appManagement.main',
      redirect: { path: '/app-management/tiles' },
      beforeEnter: async (to, from, next): Promise<void> => {
        next({ name: 'error', query: { status: '403' } });
      },
      children: [
        {
          path: 'tiles',
          name: 'appManagement.homepage',
          component: () => import('@/views/app-management/homepage.vue'),
        },
        {
          path: 'tree',
          name: 'appManagement.tree',
          component: () => import('@/views/app-management/tree.vue'),
        },
        {
          path: 'stores',
          name: 'appManagement.stores',
          component: () => import('@/views/app-management/stores.vue'),
        },
        {
          path: 'legal',
          name: 'appManagement.legal',
          component: () => import('@/views/app-management/legal.vue'),
        },
      ],
    },
    {
      path: '/tutorials',
      name: 'tutorials.main',
      redirect: { path: '/tutorials' },
      beforeEnter: async (to, from, next): Promise<void> => {
        next({ name: 'error', query: { status: '403' } });
      },
      children: [
        {
          path: '',
          name: 'tutorials',
          component: () => import('@/views/tutorials/tutorials.vue'),
          meta: { breadcrumbSkip: true },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login.vue'),
      meta: {
        layout: 'Restricted',
        layoutProps: { backgroundColor: 'dark', showCopyRight: true },
        authNotRequired: true,
      },
      beforeEnter: async (to, from, next): Promise<void> => {
        clearLocalStorage();
        next();
      },
    },
    {
      path: '/forgotten-password',
      name: 'forgotten-password',
      component: () => import('@/views/forgotten-password.vue'),
      meta: {
        layout: 'Restricted',
        layoutProps: { backgroundColor: 'dark', showCopyRight: true },
        authNotRequired: true,
      },
      beforeEnter: async (to, from, next): Promise<void> => {
        clearLocalStorage();
        next();
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/reset-password.vue'),
      meta: {
        layout: 'Restricted',
        layoutProps: { backgroundColor: 'dark', showCopyRight: true },
        authNotRequired: true,
      },
      beforeEnter: async (to, from, next): Promise<void> => {
        clearLocalStorage();
        next();
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      meta: { layout: 'Restricted' },
      component: () => import('@/views/error.vue'),
    },
  ],
});

const preventPageLeaveWithoutSave = (next: NavigationGuardNext): void => {
  saveEvent.emit('showDirtyModal');
  const unsubscribe = saveEvent.on((_) => {
    unsubscribe();
    if (_ === 'quitWithoutSave') next();
    if (_ === 'continueEditing') return false;
    return false;
  });
};

interface CustomRedirectionParams extends RouteLocationNormalized {
  query: {
    token: string;
    route?: string;
    clientId?: string;
  };
}

const loginFromUrl = async (to: CustomRedirectionParams, next): Promise<void> => {
  try {
    const userStore = useUser();
    const { token, route, clientId } = to.query;
    userStore.setToken(token);
    await userStore.getCurrentUser();
    setTimeout(() => {
      gEvent('login', { method: 'redirection' });
    }, 1000 * 20);
    if (clientId) userStore.$patch({ clientId });
    if (route) next({ path: `/${route}`, query: { forceClientUpdate: true } });
    else next({ path: '/', query: { forceClientUpdate: true } });
    if (!cookies.get('onboarding_seen')) onBoardingEvent.emit('showOnboardingModal');
  } catch (error) {
    next({ name: 'error', query: { status: error?.response?.status || 401 } });
  }
};

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  const clientStore = useClient();
  // Redirection from old back office
  if (to.query.token && to.name !== 'reset-password') loginFromUrl(to as CustomRedirectionParams, next);
  // Redirection to login if not authenticated
  else if (!isAuthenticated() && to.name !== 'login' && !to.meta.authNotRequired) next({ name: 'login' });
  // Redirection if client is churned
  else if (
    to.name !== 'error' &&
    to.name !== 'login' &&
    isAuthenticated() &&
    !isSuperAdmin() &&
    !isOrganisationOwner() &&
    clientStore.client?.status === 'churned'
  )
    next({ name: 'error', query: { status: 'client_churned' } });
  // Prevent page leave without save
  else if (from.meta?.isDirty) preventPageLeaveWithoutSave(next);
  else next();
});

window.onbeforeunload = (event: Event): void => {
  if (!router.currentRoute.value.meta.isDirty) return;
  const message = i18nt('errors.save.leaveSiteWithoutSave');
  event.preventDefault();
  // Some browsers (Chrome) require returnValue to be set
  // eslint-disable-next-line no-param-reassign
  event.returnValue = message;
};

export const redirectToOldBackOffice = (path: string): void => {
  const url = `${import.meta.env.VITE_NEOCITY_BASEURL_BACKO}#/${path || ''}`;
  window.location.href = url;
};

export default router;
